import "./Landing.css";
import { Carousel, Steps, FAQ } from "../index";
import { useGetSubmittedNebulasCount } from "../../hooks";
import YouTube from "../success/youtube.svg";
import Telegram from "../success/telegram.svg";
import Twitter from "../success/twitter.svg";


export function Landing() {
  const { submittedNebulasCount } = useGetSubmittedNebulasCount();
  const SOCIALS = [
    {
      name: "telegram",
      icon: Telegram,
      link: "https://t.me/galileoprotocolcommunity",
    },
    {
      name: "twitter",
      icon: Twitter,
      link: "https://twitter.com/galileoprotocol",
    },
    {
      name: "youtube",
      icon: YouTube,
      link: "https://www.youtube.com/@galileoprotocol.official",
    },
  ];
  return (
    <div className="main-sub__container landing-container">
      <h1 className="main-sub__container-head">
        Nebula Odyssey Upgrade
      </h1>
      {/* <span>2757 Nebula Odyssey NFTs have already been minted on June 23rd. The next and last minting for submitted NFTs is scheduled on July 31st.</span>
      <span>Last date to submit your Nebulas for upgradation is July 31st 2024.</span> */}

      <Carousel />
      {submittedNebulasCount ? (
        <h1 className="main-sub__container-head">
          {submittedNebulasCount} Nebulas submitted so far
        </h1>
      ) : null}
      
      
      <p className="main-sub__container-para">The upgrade period has ended, and no further upgrades are available at this time. Connect with us for any future updates.</p>

      <ul className="success-link__social-container">
        {SOCIALS.map((social) => (
          <li key={social.name}>
            <a href={social.link} target="_blank" rel="noreferrer">
              <img src={social.icon} alt={social.name} />
            </a>
          </li>
        ))}
      </ul>
      {/* <h2 className="main-sub__container-head">How does it work? </h2>
      <a className="landing-link__youtube" href="https://youtu.be/psWICyo2gs0?feature=shared">
        view on <img src={YouTube} alt="youtube logo" /> YouTube
      </a>

      <Steps /> */}
      {/* <FAQ /> */}
    </div>
  );
}
